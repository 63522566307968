// src/components/data-display/PropertiesContent.js

import React, { useState, useMemo, useCallback } from "react";
import { useUser } from "@auth0/nextjs-auth0/client";
import dynamic from "next/dynamic";
import { useAccessTokenQuery } from "@/hooks/queries/useAccessTokenQuery";
import { usePropertiesByUserQuery } from "@/hooks/queries/usePropertiesByUserQuery";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

// Components
import ErrorMessage from "@/components/feedback/ErrorMessage";
import AuthPageHead from "@/components/auth/AuthPageHead";
import PropertiesContentSkeleton from "../feedback/loading-skeletons/PropertiesContentSkeleton";

// Dynamically import PropertyList
const PropertyList = dynamic(() => import("@/components/data-display/PropertyList"), { ssr: false });

// SearchInput Component
const SearchInput = React.memo(({ searchTerm, onSearch, onClear }) => (
	<div className="w-full md:w-1/2 relative mb-6 md:mb-0">
		<div className="relative flex items-center">
			<MagnifyingGlassIcon className="absolute left-3 h-5 w-5 text-gray-400" />
			<input
				type="text"
				placeholder="Search by property"
				value={searchTerm}
				onChange={(e) => onSearch(e.target.value)}
				className="w-full p-2 border rounded pl-10 pr-10"
			/>
			{searchTerm && (
				<button
					onClick={onClear}
					className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500 hover:text-gray-700"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						className="w-6 h-6"
					>
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</button>
			)}
		</div>
	</div>
));

SearchInput.displayName = "SearchInput";

// PropertiesContent Component
const PropertiesContent = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const { user } = useUser();
	const auth0UserId = user?.sub;

	const { data: accessToken, isError: isErrorAccessToken, isLoading: isLoadingAccessToken } = useAccessTokenQuery();
	const {
		data: properties,
		isLoading: isLoadingProperties,
		isError: isErrorProperties,
	} = usePropertiesByUserQuery({ accessToken, auth0UserId });

	const filteredProperties = useMemo(() => {
		// Return null instead of an empty array when properties haven't loaded yet.
		// This prevents the "No properties found" message from flashing briefly during initial load.
		if (!properties) return null;
		if (!searchTerm) return properties;
		return properties.filter((property) => property.name.toLowerCase().includes(searchTerm.toLowerCase()));
	}, [properties, searchTerm]);

	const handleSearch = useCallback((value) => {
		setSearchTerm(value);
	}, []);

	const clearSearch = useCallback(() => setSearchTerm(""), []);
	const isLoading = isLoadingAccessToken || isLoadingProperties;

	if (isLoading) return <PropertiesContentSkeleton />;
	if (isErrorAccessToken || isErrorProperties) return <ErrorMessage />;

	return (
		<>
			<AuthPageHead />
			<div className="p-8 bg-off-white min-h-screen">
				<h1 className="text-2xl font-semibold mb-4">Properties</h1>
				<SearchInput searchTerm={searchTerm} onSearch={handleSearch} onClear={clearSearch} />
				<PropertyList filteredProperties={filteredProperties} isLoading={isLoading} />
			</div>
		</>
	);
};

export default PropertiesContent;
