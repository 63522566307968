// src/hooks/usePropertiesByUserQuery.js
import { useQuery } from "@tanstack/react-query";
import { getPropertiesByUser } from "@/services/getPropertiesByUser";
import { logErrorToServer } from "@/utils/logErrorToServer";

export const usePropertiesByUserQuery = ({ accessToken, auth0UserId }) => {
	return useQuery({
		// queryKey: ["propertiesByUser", auth0UserId, accessToken],
		queryKey: ["propertiesByUser", auth0UserId],
		queryFn: () => getPropertiesByUser({ accessToken, auth0UserId }),
		enabled: !!accessToken && !!auth0UserId,
		staleTime: 30 * 60 * 1000, // 30 minutes
		cacheTime: 60 * 60 * 1000, // 1 hour
		onError: (error) => {
			const errorDetails = {
				message: error.message || "An unknown error occurred",
				stack: error.stack || "No stack trace available",
				type: "Fetch Error",
				additionalInfo: { detail: "Error while fetching properties" },
			};
			logErrorToServer({ errorDetails, accessToken, auth0UserId });
		},
	});
};
